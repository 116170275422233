<template>
  <div
    class="tabs-parent"
    :class="{ 'is-darker': isDarker }"
  >
    <button
      v-for="tab in tabs"
      class="btn btn-tab"
      :class="{ 'is-active': tab.isActive, [tab.classes]: true }"
      :key="tab.name"
      :title="tab.name"
      @mousedown="$emit('onSelect', tab)"
    >
      <!-- ICON -->
      <i
        class="icon"
        :class="tab.icon"
      >
      </i>

      <!-- TEXT -->
      <span
        v-if="!tab.icon"
        class="text"
      >
        {{ tab.name }}
      </span>
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      tabs: {
        type: Array,
        required: true,
      },
      isDarker: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style scoped lang="scss">
  .tabs-parent {
    display: flex;
    width: 100%;
  }

  .btn-tab {
    position: relative;
    // padding: rem-calc(6 18);
    padding-top: 0;
    padding-bottom: 0;
    border-right: 1px solid rgba(black, 0.5);
    border-radius: 0;
    text-transform: uppercase;
    text-align: center;
    color: rgba(white, 0.3);
    transition: none;
    pointer-events: auto;

    .icon {
      width: rem-calc(26);
      font-size: rem-calc(15);
      line-height: 34px;
      // background: red;
    }

    .text {
      font-size: rem-calc(11.5);
      font-weight: 600;
    }
  }

  .btn-tab:before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: auto;
    right: -2px;
    width: 1px;
    height: 100%;
    background: rgba(white, 0.04);
  }

  .has-no-right-border {
    border-right: 0;
    &:before {
      display: none;
    }
  }

  .has-no-left-border {
    border-left: 0;
    &:before {
      display: none;
    }
  }

  .btn-tab:hover:not(.is-active) {
    background-color: rgba(black, 0.1);
  }

  .is-active {
    border-right: 1px solid rgba(black, 0.5); // Patch back in for .has-no-right-border etc
    background-color: #28282f;
    color: rgba(white, 0.8);

    // Obscure bottom border to create tab effect.
    // Preferring psuedo so pixel isn't lost (curved).
    // box-shadow: 0px 1px #28282f;
    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #28282f;
    }
    &:before {
      display: none;
    }
  }

  .is-darker .is-active {
    background-color: #212126;
    &:after {
      background: #212126;
    }
  }

  .is-darkest-active.is-active {
    background-color: #18181b;
    &:after {
      background: #18181b;
    }
  }

  .is-more {
    // padding: rem-calc(0 10);
  }
</style>
